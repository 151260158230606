import { graphql } from "gatsby";
import IndexPage from "../../components/pages";
import React from "react";
import Seo from "../../components/layout/seo";

/* export const query = graphql`
  query homeQueryEng {
    allStoryblokEntry(filter: { lang: { eq: "default" } }) {
      nodes {
        name
        slug
        content
        field_component
      }
    }
  }
`; */

export default ({ location }) => (
  <>
    <Seo title="Home" desc="" />
    <IndexPage location={location} />
  </>
);
